<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-badge-account-horizontal</v-icon>
        {{ equipe_individuo.nome }}
      </v-card-title>
      <v-divider></v-divider>

      <v-tabs v-model="tab">
        <v-tab>Dados</v-tab>
        <v-tab>Jornada Trabalho</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!--Dados -->
        <v-tab-item>
          <v-card-text class="pa-10">
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row>
                <!-- Logo -->
                <v-col cols="12" md="3">
                  <!-- <h1 class="text-h6 font-weight-bold mb-4">Avatar</h1> -->
                  <v-card
                    outlined
                    class="fill-height d-flex align-center justify-center"
                    style="position: relative"
                  >
                    <v-card flat class="pa-4">
                      <v-img
                        v-if="equipe_individuo.avatar"
                        :src="equipe_individuo.avatar"
                        contain
                      ></v-img>
                      <v-icon size="200" v-else>mdi-account</v-icon>
                    </v-card>

                    <v-btn
                      fab
                      dark
                      x-small
                      absolute
                      bottom
                      right
                      @click="onButtonClick"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        class="d-none"
                        ref="uploader"
                        @change="onFileChanged"
                      />
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>

                <!-- Dados -->
                <v-col cols="12" md="9" class="px-9">
                  <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

                  <v-row>
                    <!--Nome  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="equipe_individuo.nome"
                        label="Nome"
                        dense
                        required
                        :rules="[formRules]"
                      ></v-text-field>
                    </v-col>

                    <!-- Responsavel -->
                    <v-col cols="12" md="3">
                      <v-text-field dense readonly value="Responsavel:">
                        <template v-slot:append>
                          <v-switch
                            v-model="equipe_individuo.responsavel"
                            :false-value="false"
                            :true-value="true"
                            class="mt-0 pt-0"
                            hide-details
                          >
                          </v-switch>
                        </template>
                      </v-text-field>
                    </v-col>

                    <!--status -->
                    <v-col cols="12" md="3">
                      <v-text-field dense readonly value="Status:">
                        <template v-slot:append>
                          <v-switch
                            v-model="equipe_individuo.status"
                            :false-value="0"
                            :true-value="1"
                            class="mt-0 pt-0"
                            hide-details
                          >
                          </v-switch>
                        </template>
                      </v-text-field>
                    </v-col>

                    <!-- CPF -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-mask="`###.###.###-##`"
                        v-model="equipe_individuo.cpf"
                        label="CPF"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- RG -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="equipe_individuo.rg"
                        label="RG"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- ANIVESARIO -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Aniversário"
                        :data_sync.sync="equipe_individuo.data_aniversario"
                      />
                    </v-col>

                    <!-- Entrada -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Entrada"
                        :data_sync.sync="equipe_individuo.data_entrada"
                        required
                        :rules="[formRules]"
                      />
                    </v-col>

                    <!-- Saida -->
                    <v-col cols="12" md="4">
                      <DataField
                        label="Data Saida"
                        :data_sync.sync="equipe_individuo.data_saida"
                      />
                    </v-col>

                    <!-- Cargo Selected -->
                    <v-col cols="12" md="4">
                      <v-combobox
                        v-model="selectedCargo"
                        :items="cargos"
                        label="Cargo"
                        item-text="descricao"
                        item-value="id"
                        dense
                        required
                        :rules="[formRules]"
                      ></v-combobox>
                    </v-col>

                    <!--E-mail  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="equipe_individuo.email_pessoal"
                        label="E-mail Pessoal"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--E-email_corporativo  -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="equipe_individuo.email_corporativo"
                        label="E-mail Corporativo"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- telefone -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="equipe_individuo.telefone"
                        v-mask="`(##) ####-####`"
                        label="Telefone"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!-- celular -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="equipe_individuo.celular"
                        v-mask="`(##) #.####-####`"
                        label="Celular"
                        dense
                        required
                        :rules="[formRules]"
                      ></v-text-field>
                    </v-col>

                    <!-- whatsapp -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="equipe_individuo.whatsapp"
                        v-mask="`(##) #.####-####`"
                        label="Whatsapp"
                        dense
                      ></v-text-field>
                    </v-col>

                    <!--Telegram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="equipe_individuo.telegram"
                        v-mask="`(##) #.####-####`"
                        label="Telegram"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Endereco -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
                  <v-row>
                    <!-- CEP -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="cep"
                        v-mask="`#####-###`"
                        label="CEP"
                        dense
                        append-icon="mdi-magnify"
                        @keyup.enter="fetchCep"
                        @click:append="fetchCep"
                        :loading="loadingCep"
                      ></v-text-field>
                    </v-col>

                    <!--endereco -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Endereço"
                        v-model="equipe_individuo.endereco"
                        dense
                        readonly
                      >
                      </v-text-field>
                    </v-col>

                    <!--('numero')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Número"
                        v-model="equipe_individuo.numero"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('bairro')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Bairro"
                        v-model="equipe_individuo.bairro"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!--('('complemento')-')- -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Complemento"
                        v-model="equipe_individuo.complemento"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Cidade -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Cidade"
                        v-model="equipe_individuo.cidade"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- UF -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="equipe_individuo.uf"
                        label="UF"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Midia -->
                <v-col cols="12" class="pa-3">
                  <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
                  <v-row>
                    <!-- Instagram -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Instagram"
                        v-model="equipe_individuo.instagram"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Facebook -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Facebook"
                        v-model="equipe_individuo.facebook"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Linkedin -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Linkedin"
                        v-model="equipe_individuo.linkedin"
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <!-- Youtube -->
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Youtube"
                        v-model="equipe_individuo.youtube"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Criar Acesso -->
                <v-col cols="12" v-if="!loading">
                  <GerenciadorDeAcessos
                    acesso_para="empresa"
                    :user_id="equipe_individuo.user_id"
                    :pai_id="equipe_individuo_id"
                    :prop_acesso="equipe_individuo.acesso"
                    @update-pai="getEquipeIndividuo"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-btn :to="{ path: '/gestor/dados/equipe' }" exact> Voltar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="updateEquipeIndividuo()"
              color="secondary"
              class="mr-1 white--text"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <!--Jornada de trabalho -->
        <JornadaTrabalho :cargo="equipe_individuo.cargo" />
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import {
  fetchEquipeIndividuo,
  putEquipeIndividuo,
  uploadAvatarColaborador,
} from "@/api/gestor/empresa_equipe.js";
import { fetchCargos } from "@/api/gestor/cargos.js";
import { buscaCep } from "@/api/geral/buscaCep.js";
import { inputRequired, validaEmail } from "@/helpers/utils.js";
export default {
  name: "EquipeIndividuo",

  props: ["equipeId"],

  components: {
    DataField: () => import("../../../components/fields/DataField.vue"),
    GerenciadorDeAcessos: () =>
      import("../../../components/acessos/GerenciadorDeAcessos.vue"),
    JornadaTrabalho: () => import("./components/JornadaTrabalho.vue"),
  },

  data() {
    return {
      cargos: [],
      equipe_individuo: {
        avatar: "",
        nome: "",
        responsavel: false,
        cpf: "",
        rg: "",
        status: 1,
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        email_pessoal: "",
        email_corporativo: "",
        telefone: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        data_aniversario: "",
        data_entrada: "",
        data_saida: "",
      },
      avatar: "",
      cep: "",
      loadingCep: false,
      loading: true,
      validForm: true,
      selectedCargo: null,
      tab: 0,
      formRules: inputRequired,
      emailRule: validaEmail,
    };
  },

  watch: {
    selectedCargo() {
      if (this.selectedCargo) {
        this.equipe_individuo.cargo_id = this.selectedCargo.id;
      }
    },
  },

  computed: {
    equipe_individuo_id() {
      return this.$route.params.equipeId;
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.equipe_individuo.cep = "";
        this.equipe_individuo.cidade = "";
        this.equipe_individuo.bairro = "";
        this.equipe_individuo.uf = "";
        this.equipe_individuo.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.equipe_individuo.cep = response.cep;
            this.equipe_individuo.cidade = response.city;
            this.equipe_individuo.bairro = response.neighborhood;
            this.equipe_individuo.uf = response.state;
            this.equipe_individuo.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    getEquipeIndividuo() {
      this.loading = true;
      return fetchEquipeIndividuo(this.equipe_individuo_id)
        .then((response) => {
          this.equipe_individuo = response;
          this.cep = this.equipe_individuo.cep;
          this.selectedCargo = this.equipe_individuo.cargo;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateEquipeIndividuo() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        putEquipeIndividuo(this.equipe_individuo_id, this.equipe_individuo)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Atualizado com sucesso");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getCargos() {
      this.loadingCargos = true;
      return fetchCargos()
        .then((response) => {
          this.cargos = response;
          this.loadingCargos = false;
        })
        .catch(() => {
          this.loadingCargos = false;
        });
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      const fd = new FormData();
      fd.append("avatar", file, file.name);

      uploadAvatarColaborador(this.equipe_individuo.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Avatar atualizada com sucesso!");
            this.getEquipeIndividuo();
          }
        })
        .catch(() => {
          // console.log(error);
        });
    },
  },

  mounted() {
    this.getEquipeIndividuo();
    this.getCargos();
  },
};
</script>

<style lang="scss" scoped></style>
